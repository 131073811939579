






















import './scss/BaseViewMode.scss';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IViewMode } from "@/interfaces/IViewMode";

@Component ({
    name: 'BaseViewMode',
    components: {
        'BaseButton': () => import('@components/buttons/BaseButton.vue'),
    },
})

export default class BaseViewMode extends Vue {
    @Prop({ default: () => ([]) }) viewsMode!: IViewMode[];
    @Prop({ default: null }) currentViewMode!: IViewMode | null;

    @Emit('change-view-mode')
    emitChangeViewMode(viewMode: IViewMode | null) {
        return viewMode;
    }

    selectedViewMode: IViewMode | null = null;

    get isOneViewMode() {
        return this.viewsMode.length === 1;
    }

    get getSelectedViewMode() {
        return this.selectedViewMode;
    }

    created() {
        if (this.viewsMode.length && !this.isOneViewMode) { this.changeViewMode(this.viewsMode[0]) };
    }

    activeButton(viewMode) {
        let active: boolean;

        if (this.isOneViewMode) {
            active = this.currentViewMode !== null;
        } else {
            active = this.getSelectedViewMode === viewMode;
        }

        return active;
    }

    toggleButton() {
        if (this.currentViewMode) {
            this.emitChangeViewMode(null);
        } else {
            this.emitChangeViewMode(this.getSelectedViewMode);
        }
    }

    changeViewMode(viewMode: IViewMode | null) {
        if (this.getSelectedViewMode !== viewMode) {
            this.emitChangeViewMode(viewMode);
            this.selectedViewMode = viewMode;
        }

        if (this.isOneViewMode) {
            this.toggleButton();
        }
    }
}
